<template>
  <div class="about-me">
    <div class="Yandpro">
      <div class="animate__bounceInLeft" v-scroll="'animate__bounceIn'">
        <p class="p1">广州市景翔信息科技有限公司</p>
        <p>始于1996，是中国咨询行业的<span>开拓者</span></p>
        <p>
          提供<span>“咨询 +IT ”</span>一体化解决方案，让企业快速提升管理水平
        </p>
        <p>
          景翔经过多年的发展和实践，围绕<span
            >咨询管理、管理软件、管理培训、认证咨询</span
          >
        </p>
        <p>四大业务板块为企业提供帮助，已为过千家企业提供服务</p>
        <p>
          其中不乏世界<span>500强</span>和行业<span>龙头企业</span>，拥有丰富的<span
            >行业案例和经验</span
          >
        </p>
      </div>
      <img
        class="animate__animated"
        v-scroll="'animate__bounceInRight'"
        src="../../assets/images/bag3.png"
      />
    </div>
    <img
      class="bag animate__animated"
      v-scroll="'animate__bounceInRight'"
      src="../../assets/images/bag1.png"
      alt=""
    />
    <img
      class="bag animate__animated"
      v-scroll="'animate__bounceInLeft'"
      style="margin-top: -3px"
      src="../../assets/images/bag2.png"
      alt=""
    />
    <div class="five-div">
      <img
        class="animate__animated"
        v-scroll="'animate__fadeInLeftBig'"
        src="../../assets/images/onepage6.png"
        alt=""
      />
      <div
        class="animate__animated"
        v-scroll="'animate__bounceInUp'"
        style="margin-top: 160px"
      >
        <p>合作伙伴</p>
        <el-button @click="$router.push('./trial-use')">联系我们</el-button>
      </div>
      <img
        class="animate__animated"
        v-scroll="'animate__fadeInRightBig'"
        src="../../assets/images/onepage7.png"
        alt=""
      />
    </div>
    <div class="callandmap animate__animated" v-scroll="'animate__bounceIn'">
      <div class="call" fadeInUpBig>
        <p>联系我们</p>
      </div>

      <div
        class="mesandcode animate__animated"
        v-scroll="'animate__fadeInDown'"
      >
        <img src="../../assets/images/onepagemap.png" alt="" />
        <div class="mes">
          <p>广州市景翔信息科技有限公司</p>
          <span></span>
          <p>邮箱：aoyu@jeansawn.com</p>
          <p>客服：020-39984322</p>
          <p>邮编：510000</p>
          <p>地址：广州市番禺区洛溪新城东基商务大楼6楼</p>
        </div>
        <div class="code">
          <img src="@/assets/newhome/jingxiang.jpg" alt="" />
          <p>关注微信公众号</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "animate.css";
export default {
  data() {
    return {};
  },
  directives: {
    scroll: {
      inserted(el, binding) {
        const observer = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                const animationClass = binding.value;
                el.classList.add(animationClass);
                observer.unobserve(el);
              }
            });
          },
          {
            rootMargin: "0px",
            threshold: 0.1,
          }
        );
        observer.observe(el);
      },
    },
  },
};
</script>


<style lang="less" scoped>
@media screen and (min-width: 1200px) and (max-width: 1519px) {
  .about-me {
    .Yandpro {
      .p1 {
        font-size: 23px !important;
        margin: 40px 100px !important;
      }
      p {
        font-size: 13px !important;
        margin: 15px 100px !important;
      }
    }
    .mesandcode {
      > img {
        width: 400px !important;
      }
      .mes > p:nth-of-type(1) {
        font-size: 25px !important ;
      }
      .mes > p {
        font-size: 16px !important ;
      }
      .code {
        width: 160px !important;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .about-me {
    .Yandpro {
      .p1 {
        font-size: 18px !important;
        margin: 40px 100px !important;
      }
      p {
        font-size: 12px !important;
        margin: 12px 100px !important;
      }
    }
    .five-div {
      div {
        /deep/ .el-button {
          font-size: 14px !important;
          width: 100px !important;
          height: 35px !important;
          line-height: 35px !important;
        }
      }
    }
    .mesandcode {
      > img {
        width: 350px !important;
      }
      .mes > p:nth-of-type(1) {
        font-size: 20px !important ;
      }
      .mes > p {
        font-size: 14px !important ;
      }
      .code {
        width: 120px !important;
      }
    }
  }
}
.about-me {
  width: 100%;
  max-width: 1920px;
  min-width: 920px;
  margin: 0 auto;
  box-sizing: border-box;
  background: #ffffff;
  .topbar {
    width: 100%;
    height: 1.1625rem;
    font-size: 0.225rem;
    line-height: 1.1625rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .logo {
      margin-left: 9.22%;
      margin-top: 0.25rem;
      img {
        width: 80%;
      }
    }
    .nav {
      padding-left: 0;
      flex: 1;
      list-style: none;
      display: flex;
      justify-content: center;
      li {
        height: 0.5125rem;
        line-height: 0.5125rem;
        margin-right: 3.02%;
        text-align: center;
      }
    }
    .right {
      display: flex;
      margin-right: 6.15%;
      .phone {
        margin-right: 0.25rem;
        .el-icon-phone {
          margin-right: 0.1125rem;
          color: green;
        }
      }
      .logenr {
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          width: 1.4rem;
          height: 0.5875rem;
          text-align: center;
          line-height: 0.5875rem;
          border: 0.0125rem solid #24569c;
          border-radius: 0.1rem;
        }
        .login {
          margin-right: 0.0875rem;
          color: #24569c;
        }
        .enroll {
          color: white;
          background: #24569c;
        }
      }
    }
    .active {
      background: #24569c;
      border-radius: 0.1rem;
      color: white;
    }
  }
  .banner {
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    max-width: 1920px;
    img {
      float: left;
      width: 100%;
    }
  }
  .Yandpro {
    width: 100%;
    height: 100vh;
    margin-top: 0;
    display: flex;
    justify-content: end;
    align-items: end;
    box-sizing: border-box;
    background-image: url("../../assets/images/bag0.png");
    background-size: 100%;
    > div {
      width: 47%;
      background-color: white;
      height: 65vh;
      > p {
        margin: 25px 100px;
      }
      .p1 {
        font-size: 30px;
        color: #24569c;
        margin-top: 60px;
        margin-bottom: 50px;
      }
      span {
        color: red;
      }
    }
    > img {
      width: 47%;
      height: 65vh;
    }
    // .ytx {
    //   width: 50%;
    //   max-width: 963px;
    //   max-height: 725px;
    //   img {
    //     width: 100%;
    //     height: 100%;
    //   }
    // }
    // .profile {
    //   overflow: hidden;
    //   width: 50%;
    //   max-width: 963px;
    //   max-height: 725px;
    //   background: #f8f8f8;
    //   text-align: right;
    //   padding-left: 60px;
    //   padding-right: 12.29%;
    //   box-sizing: border-box;
    //   > p:nth-of-type(1) {
    //     margin-top: 100px;
    //     margin-bottom: 0;
    //     font-size: 30px;
    //     font-weight: bold;
    //     color: #000000;
    //   }
    //   > p:nth-of-type(2) {
    //     font-weight: bold;
    //     color: #24569c;
    //     margin-top: 10px;
    //     font-size: 17px;
    //   }
    //   > p:nth-of-type(3) {
    //     font-size: 14px;
    //     font-weight: bold;
    //     color: #666666;
    //     margin-top: 35px;
    //   }
    //   div {
    //     font-weight: 400;
    //     margin-top: 25px;
    //     font-size: 18px;
    //     color: #717171;
    //     p {
    //       margin-bottom: 10px;
    //     }
    //   }
    // }
  }
  // .middle {
  //   box-sizing: border-box;
  //   position: relative;
  //   left: 12.24%;
  //   top: -80px;
  //   width: 75.42%;
  //   max-width: 80%;
  //   background: #ffffff;
  //   border: 0.0125rem solid #d2d3d4;
  //   box-shadow: 0px 0.025rem 0.15rem 0.0125rem rgba(114, 114, 114, 0.11);
  //   list-style: none;
  //   display: flex;
  //   justify-content: space-around;
  //   align-items: center;
  //   padding: 35px 0;
  //   li {
  //     position: relative;
  //     text-align: center;
  //     p:nth-of-type(1) {
  //       font-size: 38px;
  //       font-weight: 400;
  //       color: #434343;
  //       margin-bottom: 0;
  //       color: red;
  //       display: inline;
  //     }
  //     span {
  //       font-size: 14px;
  //       font-weight: 400;
  //       color: #999999;
  //       right: -20px;
  //       top: 0px;
  //       color: red;
  //       font-size: 20px;
  //     }
  //     p:nth-of-type(2) {
  //       font-size: 14px;
  //       font-weight: 400;
  //       color: #999999;
  //       text-align: center;
  //     }
  //   }
  // }
  .callandmap {
    // width: 80%;
    border-bottom: 0.05rem solid #dddddd;
    // margin: 0 10%;
    padding-bottom: 50px;
    text-align: center;
    margin-top: 20px;
    .call {
      text-align: center;
      margin-bottom: 20px;

      p:nth-of-type(1) {
        display: inline-block;
        position: relative;
        font-size: 24px;
        font-weight: bold;
        color: #434343;
        margin-bottom: 10px;
        padding-top: 20px;
      }
      p:nth-of-type(1):after {
        content: "";
        position: absolute;
        background-image: url(../../assets/images/bag4.png);
        background-repeat: no-repeat;
        bottom: -18px;
        right: -10px;
        width: 115px;
        height: 10px;
        background-size: 100%;
      }
      p:nth-of-type(2) {
        margin-top: 25px;
      }
    }

    .mesandcode {
      margin-top: 40px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      img {
        width: 450px;
      }
      .mes {
        text-align: start;
        p {
          font-size: 15px;
          font-weight: 400;
          color: #787878;
          line-height: 32px;
        }
        p:nth-of-type(1) {
          font-size: 26px;
          font-weight: 400;
          color: #434343;
          margin-bottom: 18px;
        }
        p:nth-of-type(2) {
          margin-top: 0;
        }
        span {
          margin-bottom: 20px;
          display: inline-block;
          width: 40px;
          border-bottom: 3px solid #000000;
        }
      }
      .code {
        img {
          width: 100%;
        }
        p {
          margin-top: 0;
          text-align: center;
          font-size: 0.25rem;
          font-weight: 400;
          color: #787878;
          line-height: 0.4125rem;
        }
      }
    }
  }
  .online {
    margin-top: 42px;
    box-sizing: border-box;
    .title {
      font-size: 24px;
      font-weight: 400;
      color: #434343;
      margin-bottom: 40px;
    }
    .from {
      width: 100%;
      box-sizing: border-box;
      .news {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        > div {
          width: 31.5%;
        }
        input {
          width: 100%;
          height: 28px;
          margin-top: 12px;
          background: #ededed;
          border: 0.0125rem solid #eaeaea;
          border-radius: 5px;
        }
      }
      .txtarea {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        box-sizing: border-box;
        margin-top: 12px;
        textarea {
          width: 100%;
          height: 100px;
          background: #ededed;
          border: 0.0125rem solid #eaeaea;
          border-radius: 5px;
          resize: none;
          margin-top: 12px;
        }
      }
      .btn {
        width: 100%;
        height: 32px;
        background: #333333;
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        margin-top: 18px;
        margin-bottom: 60px;
      }
    }
  }
}
.bag {
  width: 100%;
  height: 100%;
}
.five-div {
  width: 100%;
  height: 100vh;
  display: flex;
  background-image: url("../../assets/images/onepage8.png");
  justify-content: space-around;
  align-items: center;
  width: 100% 100vh;

  div {
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-top: 80px;
      width: 180px;
    }
    > .el-button {
      background-color: #4c83f6;
      color: white;
      border-radius: 5px !important;
      margin-top: 40px;
      display: block;
      font-size: 16px !important;
      width: 160px !important;
      height: 45px !important;
      line-height: 45px !important;
    }
  }
  p {
    font-size: 24px;
    font-weight: bold;
  }
  p:nth-of-type(2),
  p:nth-of-type(3) {
    color: #2e84ec;
    margin-top: 20px;
  }
  img {
    width: 42%;
  }
  > img:nth-of-type(1) {
    margin-right: -40px;
  }
  > img:nth-of-type(2) {
    margin-left: -40px;
  }
}
</style>
